import React, {useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import {useSelector} from "react-redux";
import {StoreState} from "../../../../reducers";
import {ColorsState} from "../../../../actions/colors";
import {formatCurrency} from "../../../../currency_formatters";
import {useNavigate, useLocation} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import baseApi from "../../../../apis/baseApi";
import {getApiErrorMessage} from "../../../../utils/api_utils";
import {AuthState} from "../../../../actions/auth";
import {StudioState} from "../../../../actions/studio";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

export interface RegistrationFee {
    id: string
    name: string
    price: string
    days_duration: number
    does_not_expire: boolean
}

const RegistrationFeesView = () => {
        const navigate = useNavigate()
        const colorsState = useSelector<StoreState, ColorsState>(state => state.colors)
        const authState = useSelector<StoreState, AuthState>(state => state.auth)
        const studioCurrency = useSelector<StoreState, string>(state => (state.studio?.studio?.preferences?.currency ?? "mxn"))
        const studioState = useSelector<StoreState, StudioState>(state => state.studio)
        const [fetchingLink, setFetchingLink] = useState(false);
        const [errorMsg, setErrorMsg] = useState<string>()
        const location = useLocation() as { state: { registrationFees: RegistrationFee[] } }
        const {registrationFees} = location.state

        const [alert, setAlert] = useState(<div/>)

        useEffect(() => {
            if (studioState.studio.id === "d244fadb-d01c-4b27-84a5-c114512f835e") {
                setAlert(
                    <div className="row border border-info rounded mt-2 mx-1">
                        <div className={"col-12 text-center"}>
                            <div className="text-center mt-5 font-size-h4">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" /> En estos momentos nos encontramos llenos, para inscribirte por favor envíanos un Whatsapp.
                                <div className=" mb-2">
                                    <Button onClick={() => {
                                        window.open("https://wa.link/wcswwi", "_blank")
                                    }} variant="outline-success" size="sm">
                                        <FontAwesomeIcon size="lg" icon={faWhatsapp} /> Contáctanos
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>)
            }
        }, []);


        if (!registrationFees) return <React.Fragment/>
        return (
            <div>
                <div className="mt-10">
                    <Button onClick={() => navigate(-1)} size="sm" variant="outline-primary"><FontAwesomeIcon
                        icon={faArrowLeft}/> Atrás</Button>
                </div>
                <div>
                    {alert}
                </div>
                <div className="badge badge-danger text-center mt-5 font-size-h4">
                    {errorMsg}
                </div>
                <div className="row mt-10">
                    {registrationFees.map(fee => {
                        return <div
                            key={fee.id}
                            className="col-6 col-md-3"
                            onClick={() => {
                                if (fetchingLink) return
                                if (!authState.isLoggedIn) {
                                    // @ts-ignore
                                    const params = new URLSearchParams(location.search)
                                    const studio = params.get('studio') || undefined
                                    const branch = params.get('branch') || undefined
                                    let path = '/login';
                                    if (studio) {
                                        path += `?studio=${studio}`
                                    }
                                    if (branch) {
                                        path += `&branch=${branch}`
                                    }
                                    navigate(path)
                                    return;
                                }
                                setFetchingLink(true)
                                let originUrl = window.location.origin
                                if (studioState.studio.preferences.payment_processor === "ST") {
                                    baseApi.post("/stripe/link/registration-fee/", {
                                        registration_fee: fee.id,
                                        success_url: `${originUrl}/purchase-details`,
                                        url: originUrl,
                                    }).then(resp => {
                                        window.parent.location.href = resp.data.url
                                    }).catch(err => {
                                        const msg = getApiErrorMessage(err)
                                        setErrorMsg(msg)
                                        setFetchingLink(false)
                                    })
                                } else if (studioState.studio.preferences.payment_processor === "WM") {
                                    baseApi.get(`/wompi/link/registration-fee/?registration_fee=${fee.id}`).then(resp => {
                                        window.parent.location.href = resp.data.urlEnlace
                                    }).catch(err => {
                                        const msg = getApiErrorMessage(err)
                                        setErrorMsg(msg)
                                        setFetchingLink(false)
                                    })
                                }
                            }}>
                            <div
                                className={`card card-custom shadow-xs min-h-200px mb-5 py-10 text-center font-size-lg ${colorsState.text === 'white' ? 'text-white' : 'text-dark'} ${fetchingLink ? 'text-muted' : ''}`}
                                style={{
                                    cursor: "pointer",
                                    backgroundColor: colorsState.bg,
                                    borderRadius: "20px"
                                }}>
                                <div className="px-1">
                                    <div className=" display3 font-weight-bold mt-n2">{fee.name} </div>
                                    <div className=" font-size-  mt-n2 mb-4">Vigencia: {fee.days_duration} días
                                    </div>
                                    <div style={{color: colorsState.fg}}
                                         className={` font-weight-bold display-4 ${fetchingLink ? 'text-muted' : ''}`}>
                                        {formatCurrency(parseFloat(fee.price), studioCurrency)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        );
    }
;

export default RegistrationFeesView;
